.mid-health {
    margin-left: 10em;
    display: flex;
    margin-top: 0em;
    gap: 0.5em;
}

.product_images-health img {
    min-width: 10rem;
    height: 13.5rem;
    margin-top: 10em;
}


@media only screen and (max-width: 800px) {
    .mid-health {
        margin-left: -1em;
    }

    .center-health {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 0em;
        color: white;
        width: 100%;
        margin-left: -2em;
    }


}