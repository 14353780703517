.fs {
    font-size: 1.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.center-prospecting {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fs-prospecting {
    font-size: 1.4em;
}

.img-prospecting {
    height: 56vh;
    /* width: 33%; */
}

.fs-services {
    font-size: 1em;
}

.rowside {

    justify-content: center;
    align-items: center;
}

.transparent {
    background-color: transparent;
}

.cntr {
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.mid{
    width: 70%;
    margin-left: 11em;
}
.size{
    font-size: 1em;
}
.sklls-box{
    display: flex;
}
.adjust{
    display: flex;
}
.adjust1{
    display: flex;
    justify-content: space-around;
}

@media only screen and (max-width: 800px) {

    .fs-prospecting {
        font-size: 1.3em;
    }
    .adjust{
        display: flex;
        flex-direction: column;
    }
    .adjust1{
        display: flex;
        justify-content: space-around;
    }
    .sklls-box{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .size{
        font-size: 0.85em;
    }

    .img-prospecting {
        height: 56vh;
        width: 100%;
    }
    .img-prospecting1 {
        height: 23vh;
        width: 100%;
    }
    .img-prospecting2 {
        height: 10vh;
        width: 100%;
    }
    .img-prospecting3 { 
        height: 40vh;
        width: 100%;
    }

    .center-prospecting {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
    }

    .rowside {
        flex-direction: column;

    }

    .cntr {
        width: 96%;
    }
    .mid{
        
        margin-left: 0em;
    }
}