.img-online {
    height: 73vh;
    width: 100%;
    border-radius: 15px;
}
.img-webinar {
    height: 73vh;
    width: 100%;
    border-radius: 15px;
}

.webinar {
    margin-left: 2em;
    width: 45%;
    margin-top: 2em;
    height: 7vh;
}

@media only screen and (max-width: 800px) {
    .img-online {
        height: 23vh;
    }

    .webinar {
        width: 100%;
        margin-left: 0em;
        height: 7vh;

        font-size: 1.4em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .img-webinar {
        height: 21vh;
    }
}