* {
    margin: 0;
    padding: 0;
}

.img-home {
    height: 69vh;
    border-radius: 23px;
}

.get-in-touch {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
    font-family: ui-serif;
    color: white;
}

.wts img {
    position: fixed;
    bottom: 3em;
    width: 10%;
    right: 2em;
    float: left;
    height: 18%;
    z-index: 1;
    border-radius: 80px;
    box-shadow: 4px 3px -1px 17px white;
}

.id-account-height {
    height: 8vh;
}

.paperplan {
    /* margin-top: -1.3em; */
    /* height: 9vh; */
    font-size: 1em;
}

.center-home {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
}

.mt {
    margin-top: -2em;
}

.center-home a:hover {
    background-color: green;
    margin-top: 0.3em;              
    /* padding: 1em; */
}

@media only screen and (max-width: 800px) {
    .boxes {
        display: flex;
        flex-direction: column;
    }

    .paperplan {
        /* margin-top: -2em; */
        color: white;
    }

    .img-offlineplan {
        height: 46vh;
        width: 100%;
    }

    .boxes .box {
        padding: 19px;
        width: 99%;
        height: 45vh;
        margin-left: 0em;
    }

    .img-home {
        height: 25vh;
    }

    .size-nb {
        font-size: 1.3em;
    }

    .wts img {
        height: 10%;
        width: 25%;
        border-radius: 80px;
    }

    .id-account-height {
        height: 7vh;
    }
    .get-in-touch {
        font-size: x-large;
        
    }
    .img-hosting1 {
        height: 49vh;
        width: 100%;
    }
    .img-hosting2 {
        height: 25vh;
        width: 100%;
    }
    .center-home { 
      font-size: 1em; 
    } 
 
} 