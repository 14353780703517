.gap {
    text-align: center;
    gap: 23em;
    /* display: flex; */
    width: 70%;
    justify-content: center;
    align-items: center;
    margin-left: 14em;
    border-radius: 7px;
    position: relative;
    top: 1.6em;
    height: 10vh;
    row-gap: 4em;
    place-items: 6em;
    gap: 9em;
}

.two-column {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0b664f;
}

button a {
    text-decoration: none;
    font-size: 1em;
}

.container-footer {
    padding-bottom: 2em;
}

.container-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    background-color: #061231f0;
}

h3 {
    text-align: center;
    text-transform: capitalize;
    color: #00c2ff;
    /* font-size: 3em; */
    font-weight: bold;
    font-family: inherit;
}

.xyz {
    display: grid;
    justify-content: center;
    width: 100%;
    line-height: 0;
}

.xyz h1 {
    font-size: 1.6em;
    padding: 5px;
    color: white;
    display: flex;
    justify-content: center;
}

.footer-bottom ul {
    display: flex;
    gap: 3em;
    align-items: center;
    list-style: none;
}

.container-footer {
    padding-bottom: 2em;
}

.diff-footer {
    gap: 6em;
    row-gap: 1em;
    display: flex;
    flex-direction: row;
    margin-top: 3em;
}

.container-footer {
    display: flex;

}
.large{
    font-size: large;
}
.get-started button{
    height: 7vh;
    width: 14vw;
    border-radius: 17px;
}
.linee{
    line-height: 0.5em;
}
.fs-footer{
    font-size: 1em;
}



@media only screen and (max-width: 800px) {

    .two-column {
        margin-left: 3em;
    }

    .xyz {
        display: flex;
        flex-direction: column;
    }

    .footer-bottom ul {
        display: flex;
        /* line-height: 1em; */
        flex-direction: column;
    }

    .container-footer {
        display: flex;
        flex-direction: column;
    }

    .started {
        width: max-content;
    }

    .two-column {
        display: flex;
        justify-content: start;
        width: 90%;
        margin-left: 1em;
        height: 34vh;
        flex-direction: column;
    }

    .button {
        height: 7vh;
        width: 46vw;
        border-radius: 17px;
    }
    .large{
        font-size: x-large;
    }
    .get-started{
        margin-top: -3em;
    }
    .get-started button {
     
        width: 59vw;
    }
    .fs-footer{
        font-size: 0.9em;
    }
    .mid-product{
        margin-left: -4em;
    }
}