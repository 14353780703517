.container-nb {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48vhvh;
    /* width: 83vw; */
    border-bottom-color: yellowgreen;
    box-sizing: border-box;
    background-color: seashell;
    background: transparent;
    box-shadow: 4px -5px 6px white;

}

.bg {
    background: rgb(70 61 61)url('../images/bg2.jpeg');
    height: 100vh;
    /* margin-top: -11em; */
    background-size: cover;
    background-blend-mode: multiply;
    border-radius: 49px;
}

.input-box2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.remember-forgot2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

form button {
    width: 80%;
    height: 45px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 16px;
    color: #333;
    font-weight: 700;
}

.register-link2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

form .input-box2 {
    position: relative;
    width: 100%;
    height: 33px;
    margin: 30px 0;
    /* background: salmon; */
}

.input-box2 input {
    width: 25vw;
    height: 113%;
    color: rgb(88, 213, 238);
    background: transparent;
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .1);
    border-radius: 7px;
    font-size: 16px;
}

.input-box2 .icon2 {
    position: absolute;
    right: 25px;
    top: 80%;
    translate: 0 -50%;
    margin: -15px 0 15px;
}

form .remember-forgot2 {
    display: flex;
    justify-content: space-around;
    font-size: 14.5px;
    margin: -15px 0 15px;
}

.register-link2 p a:hover {
    text-decoration: underline;
}

.form-box2 {
    border-bottom-color: #fff;
    margin-top: -12em;
}

form .register-link2 {
    text-decoration: none;
}

.btn0 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2em;
    margin-top: 3em;
}

.btn0:hover {
   color: blue;
}


.service {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* margin-top: 10em; */
}

.boxes {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 24px; */
    margin-top: 3em;
}

.boxes .box {
    padding: 19px;
    width: 29%;
    height: 45vh;
    margin-left: 3em;
}

.box {
    background-color: #eee;
    height: 52vh;
    width: 26vw;
    box-shadow: 4px -3px 5px powderblue;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.box:hover {
    box-shadow: 1px 1px 4px #07ffda;
    border-radius: 11px;
}

img {
    min-width: 6rem;
    height: 8.5rem;
}
.form-box2 button:hover{
    background-color: #07ffda;
}


@media only screen and (max-width: 800px) {
    .input-box2 input {
        width: 78vw;
        height: 5vh;

    }

    .form-box2 {
height: 92vh;
        /* margin-left: 3em; */
    }

    .bg {
        width: 107%;
        margin-left: -1em;
        border-radius: 49px;
    }

    .register-link2 { 
        margin-top: 2em;
    }

    form .input-box2 {
        height: 3em;
    }
    .form-box2-reg{
        margin-bottom: 17em;
    }
   
}