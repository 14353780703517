.img-nb {
    width: 47%;
}

.container-nb {
    display: flex;


}

.fixed {
    width: 40%;
    position: fixed;
    background-attachment: fixed;
    z-index: 1;
}

.img-chandan {
    width: 36%;
    margin-top: -2m;

}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    box-shadow: 3px 7px 10px white;

}

.card-header a {
    font-size: 1.2em;
    background-color: #190883;
}

.center-hand {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.img-hand {
    width: 20%;
}

.img-profile {
    height: 72vh;
    border-radius: 23px;
}

.img-profile {
    height: 95vh;
}

.shdw {
    box-shadow: 0px 7px 7px white;
}

.fix {
    background-color: #3b6821;
    width: 20%;
    height: 7vh;
    border-radius: 4px;
    /* float: right; */
    display: flex;
    margin-left: -3em;
    position: fixed;
    justify-content: center;
    align-items: center;
}

.container-payout {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
}

.flx {
    display: flex;
    justify-content: space-around
}

.flx2 {
    width: 35%;
    font-size: 1em;
    color: wheat
}

.flx img {
    width: 40%;
}

.image {
    height: 100vh;
    width: 44%;
}

.bich-me {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.expand {
    height: 3em;
    width: 34em;
    border-radius: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 14em;
    font-size: 1.1em;
    background-color: transparent;
    color: whitesmoke;
    border-left-color: aqua;
    border-right-color: aqua;
    border-top-color: aqua;
    border-bottom-color: aqua;
}
.align{
    width: 75%;
    margin-left: 9em;
}

@media only screen and (max-width: 800px) {
    .img-nb {
        width: 98%;
    }

    .container-nb {
        display: flex;
        flex-direction: column;

    }

    .img-hand {
        width: 103%;
        height: 20vh;
    }

    .center-hand {
        display: flex;
        flex-direction: column;
    }

    .fs {
        font-size: 1.4em;
    }

    .img-profile {
        height: 34vh;
        width: 100%;
    }

    .bada {
        font-size: 1em;
        line-height: 1.3em;
    }

    .fix {
        width: 51%;
        height: 6vh;
        border-radius: 4px;
        /* float: right; */
        display: flex;
        font-size: 1.2em;
        margin-left: -1em;
        margin-top: 6em;
        font-family: system-ui;
    }

    .fix a {
        text-decoration: none;
    }

    .flx {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .flx2 {
        width: 95%;
        color: wheat
    }

    .flx img {
        width: 95%;
    }

    .image {
        height: 43%;
    }

    .expand {
        height: 3.5em;
        width: 19em;
        margin-left: 0.6em;
    }
    .align{
        width: 100%;
        margin-left: 0em;
    }
}