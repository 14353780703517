#title{
    color: white;
}
#description{
    color: white;
}
#tag{
    color: white;
}
#exampleFormControlInput1{
    color: white;
}
