.boxShadow{
    box-shadow: 4px 5px 9px white;
    /* width: 85%; */
}

@media only screen and (max-width: 800px){
    .boxShadow{
        box-shadow: 4px 5px 9px white;
        width: 85%;
    }
}