/* .row{
  display: flex;
} */

body{
  background-color: #2c2e31;
}
#title{
  background-color: transparent;
}
#description{
  background-color: transparent;
}
#tag{
  background-color: transparent;
}
#input{
  color: black;
}
