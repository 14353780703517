.product-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    margin-top: 3em;
    color: white;
}

.product-data-warranty {
    /* width: 100%; */
    /* display: flex; */
    /* flex-direction: row; */
    /* justify-content: space-between; */
    align-items: center;
    border-bottom: 1px solid #ccc;
    margin-bottom: 1rem;
}

.container-product img {
    width: 100%;
    border-radius: 9px;
    height: 59vh;
}


.mid {
    /* display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center; */
    margin-left: 10em;
    display: flex;
    margin-top: 0em;
    gap: 0.5em;

}

.box-product {
    border-radius: 7px;
    width: 71vw;
    background-color: white;
    margin-left: 6em;
    margin-top: 2em;
    margin-bottom: -1em;
    position: relative;
    top: -10em;
}

.product_images img {
    min-width: 10rem;
    height: 7.5rem;
}

/* .box{
    height: 55vh;
    width: 26vw;
    background-color: aqua;
    margin-left: 5em;
    margin-top: 2em;
    margin-bottom: -1em;
    position: relative;
    top: -10em;
} */
.boxes .box-product {
    padding: 3px;
    /* width: 22%; */
}

.box1-product img {
    min-width: 16rem;
    height: 8.5rem;
    margin-top: -2em;
}

.box1-product {
    /* background-color: #eee; */
    height: 42vh;
    width: 26vw;
    box-shadow: 4px -3px 5px powderblue;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 1em;

}

.box1-product:hover {
    box-shadow: 1px 1px 4px #17254a;
    border-radius: 11px;
}

.wrap {
    display: flex;
    justify-content: inherit;
    align-items: center;
    gap: 1em;
}

.flex {
    display: flex;
    text-align: left;
    /* width: 109%; */
    /* margin-left: 7em; */
    gap: 4em;
}

.supplement {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.box1-productes {
    display: flex;
    justify-content: center;
    width: 84%;
    margin-left: 7em;
    margin-top: 3em;
}

.wrap {
    display: flex;
    flex-direction: column;
    gap: 1px;
    margin-top: 1em;

}

.w-wrap {
    margin-top: 1em; 
    font-size: 1.1em;
    /* width: 64%; */
    font-family: math;
    padding: 0em 3em;
    /* line-height: 4vh; */
}


@media only screen and (max-width: 800px) {

    .flex {
        display: flex;
        flex-direction: column;
        margin-left: 3em;
    }

    .mid {
        margin-left: -4em;
    }

    .box1-productes {
        flex-direction: column;
    }

    .box1-product {
        /* background-color: #eee; */
        height: 25vh;
        width: 88vw;
        box-shadow: -4px -3px 5px powderblue;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: -1em;
    }

   

    .box-product {
        height: 60vh;
        width: 94vw;
        background-color: white;
        margin-left: 0em;
    }

    .box1-productes {

        margin-left: 3em;
    }

    .box1-product img {
        height:  6.5rem;
    }

    .big {
        font-size: 1.2em;
    }

    .container-product img {

        height: 30vh;
    }

    .w-wrap {
        padding: 0em 0.5em;
        line-height: 3vh;
    }

}